import { FC, PropsWithChildren } from 'react'
import { Link } from 'react-router-dom'
import style from './navbar.module.scss'
import { BreadCrumbType } from './types'

interface BreadCrumbsProps {
  breadCrumbs: BreadCrumbType[]
}

const BreadCrumbs: FC<PropsWithChildren<BreadCrumbsProps>> = ({ breadCrumbs }) => {
  return (
    <div className={style.breadCrumbs}>
      {breadCrumbs.map(({ name, href }, index) => (
        <Link to={href} key={`crumb-${index}`}>
          {name}
        </Link>
      ))}
    </div>
  )
}

export default BreadCrumbs
