import DashboardLayout from 'layouts/DashboardLayout'
import PageNotFoundImage from 'assets/pannels/404.min.svg'
import styled from 'styled-components'

const NotFoundContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
  h1 {
    font-family: 'Dosis', sans-serif;
    color: #65676d;
    text-align: center;
  }
  img {
    max-height: 400px;
    object-fit: cover;
    min-height: 200px;
  }
`

function PageNotFound() {
  return (
    <DashboardLayout title='Page not found' breadCrumbs={[{ name: 'Dashboard', href: '/' }]}>
      <NotFoundContainer>
        <h1>Page not found</h1>
        <img src={PageNotFoundImage} alt='Page Not found' />
      </NotFoundContainer>
    </DashboardLayout>
  )
}

export default PageNotFound
