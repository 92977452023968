import { faLanguage } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC, ReactElement, PropsWithChildren } from 'react'
import BreadCrumbs from './BreadCrumbs'
import Notifications from './Notifications'
import style from './navbar.module.scss'
import { BreadCrumbType } from './types'

interface NavBarProps {
  breadCrumbs: BreadCrumbType[]
  search?: ReactElement
}

const Navbar: FC<PropsWithChildren<NavBarProps>> = ({ breadCrumbs, search }) => {
  return (
    <nav className={style.navbar}>
      <BreadCrumbs breadCrumbs={breadCrumbs} />
      <div className={style.left}>
        {search}
        <Notifications />
        <button>
          <FontAwesomeIcon icon={faLanguage} />
        </button>
      </div>
    </nav>
  )
}

export default Navbar
