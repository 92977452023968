import classNames from 'classnames'
import style from './chat-icon.module.scss'
import { chatType } from './types'

function ChatIcon({ avatar, status = 'offline', unread = 0 }: chatType) {
  return (
    <button className={classNames(style.chatAvatar, style[status], { [style.noNotif]: unread !== 0 })}>
      <img src={avatar} />
      {unread !== 0 && <span className={style.unread}>{unread < 100 ? unread : '+99'}</span>}
    </button>
  )
}

export default ChatIcon
