import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons'
import Modal, { useModal } from 'components/Modal'
import Button from 'components/Button'
import { useUserStore } from 'store/userStore'

function Logout() {
  const logoutModal = useModal()

  const { logout } = useUserStore()

  return (
    <>
      <button onClick={() => logoutModal.show()}>
        <FontAwesomeIcon icon={faRightFromBracket} />
        <span>Logout</span>
      </button>
      <Modal modal={logoutModal}>
        <Modal.Title>Are you sure you wish to logout</Modal.Title>
        <Modal.Footer center>
          <Button onClick={() => logout()} size='md' varient='secondary'>
            Confirm
          </Button>
          <Button onClick={() => logoutModal.hide()} size='md' varient='danger'>
            Decline
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default Logout
