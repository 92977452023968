import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './scss/global.scss'
import { BrowserRouter } from 'react-router-dom'
import axios from 'axios'
import { QueryClientProvider } from 'react-query'
import { reactQueryClient } from './react-query-config'
import * as Sentry from '@sentry/react'
import { Leva } from 'leva'

axios.defaults.baseURL = '/api'

// TODO: Make this EnvVar dependent
const SENNTRY_ON = false

if (SENNTRY_ON) {
  Sentry.init({
    dsn: 'http://c792c87e3dfe465a3c83381612443084@92.222.23.82:9000/3',
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', 'http://localhost:3000', /^http:\/\/3geoarin\.com:8000\/api/],
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}

const root = createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={reactQueryClient}>
        <Leva />
        <App />
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>
)

/*
 * If you want to start measuring performance in your app, pass a function
 * to log results (for example: reportWebVitals(console.log))
 * or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 */

reportWebVitals()
