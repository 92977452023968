import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import classNames from 'classnames'
import Flex from 'components/Flex'
import { IndexationData } from 'models/file'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import styles from './styles.module.scss'
import { myProjectStore } from 'store/myProjectStore'
import { reactQueryClient } from 'react-query-config'
import ProgressBar from 'components/ProgressBar'
import { FullWidth } from 'components/Resizers'

function ScanStatus() {
  const { id } = useParams()
  const projectId = Number(id)

  const { getProject, updateProject, isProjectScanning } = myProjectStore()
  const [finished, setFinisehed] = useState(false)
  const project = getProject(projectId)

  const isScanning = isProjectScanning(projectId)

  const { data } = useQuery<IndexationData>(
    `get-project-${projectId}-status`,
    () => {
      setFinisehed(false)
      const url = `projects/get_index_by_project/${projectId}`
      return axios.get<IndexationData>(url).then(res => {
        if (res.data.scan_status === 'Finished') {
          updateProject({ ...project, scan_status: 'Finished' })
          setFinisehed(true)
          reactQueryClient.refetchQueries(`get-project-${projectId}-documents-data`)
          reactQueryClient.refetchQueries(`get-project-${id}-details`)
        }
        return res.data
      })
    },
    {
      enabled: !isNaN(projectId) && Number(projectId) !== 0 && isScanning,
      refetchInterval: finished ? false : 3000,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
    }
  )

  if (data && data.scan_status === 'In Progress') {
    const percentage = Math.round((data.files_scanned / data.files_number) * 100)

    return (
      <div className={classNames(styles.container)}>
        <Flex flexDirection='column' gap={10}>
          <Flex className={styles.spinner}>
            <FontAwesomeIcon icon={faSpinner} spin color='white' />
          </Flex>
          <div className={styles.description}>
            <span>Scanning</span>
            <span>{percentage}%</span>
            <FullWidth>
              <ProgressBar small count={data.files_scanned} total={data.files_number} hideCount />
            </FullWidth>
          </div>
        </Flex>
      </div>
    )
  }

  return <></>
}

export default ScanStatus
