import classNames from 'classnames'
import Chat from 'components/Chat'
import LoadingPage from 'components/LoadingPage'
import { FC, ReactElement, useContext, useEffect, PropsWithChildren } from 'react'
import { Helmet } from 'react-helmet'
import { projectName } from 'settings'
import Dashboard from 'views/Dashboard'
import Navbar from './Navbar'
import { BreadCrumbType } from './Navbar/types'
import ScanStatus from './ScanStatus'
import style from './dashboard-layout.module.scss'

interface DashboardLayoutProps {
  breadCrumbs?: BreadCrumbType[]
  noPadding?: boolean
  title: string | null
  hide?: boolean
  isLoading?: boolean
  hasChat?: boolean
  scanStatus?: boolean
  search?: ReactElement
}

const DashboardLayout: FC<PropsWithChildren<DashboardLayoutProps>> = ({
  hide = false,
  children,
  breadCrumbs = [],
  noPadding = false,
  title = null,
  isLoading = false,
  hasChat = true,
  scanStatus = true,
  search,
}) => {
  const { hide: hideSidebar, show } = useContext(Dashboard.Context)
  useEffect(() => {
    if (hide) {
      hideSidebar()
      return () => show()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading)
    return (
      <main className={classNames(style.main)}>
        <Helmet>
          <title>{title ? `${title} | ${projectName}` : projectName}</title>
        </Helmet>
        <Navbar breadCrumbs={breadCrumbs} />
        <div className={classNames(style.container, { [style.hasChat]: hasChat })}>
          <div className={classNames({ [style.innerContainer]: true, [style.noPadding]: noPadding })}>
            <LoadingPage />
          </div>
          {hasChat && <Chat />}
        </div>
      </main>
    )

  return (
    <main className={classNames(style.main)}>
      <Helmet>
        <title>{title ? `${title} | ${projectName}` : projectName}</title>
      </Helmet>
      <Navbar breadCrumbs={breadCrumbs} search={search} />
      <div className={classNames(style.container, { [style.chat]: hasChat })}>
        <div className={classNames({ [style.innerContainer]: true, [style.noPadding]: noPadding })}>{children}</div>
        {hasChat && <Chat />}
      </div>
      {scanStatus && <ScanStatus />}
    </main>
  )
}

export default DashboardLayout
