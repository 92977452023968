import create from 'zustand'
import { HashList } from 'hashlist-typescript'
import { ProjectType } from 'models/project'
import axios from 'axios'
import { OneRequiredParam } from 'types'

type MyProjectStoreType = {
  projects: HashList<ProjectType>
  isLoading: boolean
  isError: boolean
  getProjects: () => void
  getProject: OneRequiredParam<string, ProjectType | null>
  updateProject: OneRequiredParam<ProjectType>
  isProjectScanning: (OneRequiredParam<string, boolean>)
}

export const myProjectStore = create<MyProjectStoreType>((set, get) => ({
  projects: new HashList<ProjectType>(),
  isLoading: true,
  isError: false,

  getProjects: () => {
    axios
      .get<ProjectType[]>('/projects')
      .then(res => {
        return set({ projects: new HashList<ProjectType>(...res.data), isLoading: false, isError: false })
      })
      .catch(() => {
        return set({ projects: new HashList<ProjectType>(), isLoading: false, isError: true })
      })
  },

  getProject: (id: number) => {
    return get()
      .projects.toArray()
      .find(project => project.id === id) || null
  },

  isProjectScanning: (id: number) =>
    get().getProject(id)?.scan_status !== "Finished",

  updateProject: (newProject: ProjectType) => {
    const projects = get()
      .projects.toArray()
      .filter(project => project.id !== newProject.id)
    const newList = new HashList<ProjectType>(...[...projects, newProject])
    set({ projects: newList })
  },
}))
