import classNames from 'classnames'
import PrivateRoute from 'layouts/PrivateRoute'
import { createContext, useState } from 'react'
import { Outlet } from 'react-router-dom'
import Sidebar from 'components/Sidebar'
import style from './dashboard.module.scss'

interface DashboardContextValues {
  closed: boolean
  hide: () => void
  show: () => void
  toggle: () => void
}

const DashboardContext = createContext<DashboardContextValues>({
  closed: false,
  hide: () => null,
  show: () => null,
  toggle: () => null,
})

function DashBoard() {
  const [isClosed, setIsClosed] = useState<boolean>(closed)

  return (
    <DashboardContext.Provider
      value={{
        closed: isClosed,
        hide: () => setIsClosed(true),
        show: () => setIsClosed(false),
        toggle: () => setIsClosed(!isClosed),
      }}
    >
      <PrivateRoute>
        <div className={classNames(style.dashboardContainer, { [style.closed]: isClosed })}>
          <Sidebar />
          <Outlet />
        </div>
      </PrivateRoute>
    </DashboardContext.Provider>
  )
}

export default Object.assign(DashBoard, { Context: DashboardContext })
