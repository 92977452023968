import { faBell } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { useState } from 'react'
import style from './navbar.module.scss'

type NotificationsStatusTypes = 'important' | 'warning' | 'success'

const Notifications = () => {
  const [status, setStatus] = useState<NotificationsStatusTypes>('important')

  return (
    <button
      className={classNames({
        [style.hasStatus]: true,
        [style[status]]: true,
        [style.animated]: true,
      })}
      onClick={() => {
        if (status === 'important') setStatus('warning')
        else if (status === 'warning') setStatus('success')
        else setStatus('important')
      }}
    >
      <FontAwesomeIcon icon={faBell} />
    </button>
  )
}

export default Notifications
