import create from 'zustand'
import { LoaderFunction } from 'types'
import axios from 'axios'
import { UserInfoResponse, UserType } from 'models/user'

type UserStoretype = {
  user: UserType | null
  isLoading: boolean
  verifyUser: () => void
  logout: () => void
  authenticateUser: LoaderFunction<UserType>
}

export const useUserStore = create<UserStoretype>(set => ({
  isLoading: true,
  user: null,
  authenticateUser: (data: UserType) => set(() => ({ user: data })),

  logout: () =>
    set(() => {
      localStorage.clear()
      set({ user: null })
    }),
  verifyUser: () => {
    new Promise<UserInfoResponse>((resolve, reject) => {
      const jwt = localStorage.getItem('jwt')
      if (!jwt) reject(new Error('access_token not found'))
      else {
        axios.defaults.headers.common.Authorization = `Bearer ${jwt}`
        axios
          .get<UserInfoResponse>('/users/me')
          .then(res => {
            resolve(res.data)
          })
          .catch(reject)
      }
    })
      .then((data: UserInfoResponse) => {
        const { access_token } = data
        axios.defaults.headers.common.Authorization = `Bearer ${access_token}`
        localStorage.setItem('jwt', access_token)
        set({ user: data.user, isLoading: false })
      })
      .catch(() => {
        localStorage.clear()
        set({ user: null, isLoading: false })
      })
  },
}))
