import style from './card.module.scss'
import { ReactNode, Children, Dispatch, isValidElement, cloneElement } from 'react'
import classNames from 'classnames'
import { CardBody } from './Components'

interface TabsBodyProps {
  children: ReactNode
  selected: string
}

interface TabsHeaderProps {
  children: ReactNode
  selected: string
  setSelected: Dispatch<string>
}

interface CardTabsItemProps {
  title: string | ReactNode
  select: string
  children: ReactNode
  show?: boolean
  disabled?: boolean
  noPadding?: boolean
  scroll?: boolean
  background?: string
}

export interface CardTabsProps {
  children: ReactNode
  defaultSelected: string
}

export function TabsBody({ children, selected }: TabsBodyProps) {
  return (
    <div className={style.tabContainer}>
      {Children.map(children, child => {
        if (!isValidElement(child)) return null

        const select = child.props.select
        return cloneElement(child, { show: select === selected })
      })}
    </div>
  )
}

export function CardTabsHeader({ children, selected, setSelected }: TabsHeaderProps) {
  return (
    <div className={style.tabsHeader}>
      {Children.map(children, child => {
        if (!isValidElement(child)) return null

        const title = child.props.title
        const select = child.props.select
        const disabled = child.props.disabled || false

        return (
          <button
            disabled={disabled}
            className={classNames({ [style.active]: select === selected })}
            onClick={() => setSelected(select)}
          >
            {title}
          </button>
        )
      })}
    </div>
  )
}

export function CardTabsItem({ show = false, children, noPadding, scroll, background }: CardTabsItemProps) {
  if (!show) return null
  return (
    <CardBody h100 noPadding={noPadding} scroll={scroll} background={background}>
      {children}
    </CardBody>
  )
}
