import styled from 'styled-components'

export const Container = styled.button``

export const Circle = styled.div<{ varient: string }>`
  width: 5rem;
  height: 5rem;
  background: ${({ varient }) => (varient === 'success' ? '#03AA6F' : '#F05454')};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #112031;
`

export const MessageContainer = styled.div`
  h1 {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 20px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  height: 100%;
`

export const FileInputMask = styled.button`
  width: 100%;
  height: 100px;
  border-radius: 7px;
  background-color: white;
  span {
    text-decoration: underline;
  }
`

export const FormContainer = styled.div`
  margin-top: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
  p {
    font-size: 12px;
    color: #93a0b1;
  }
  span {
    color: #f05454;
  }
`
