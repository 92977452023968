import style from '../sidebar.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMaximize } from '@fortawesome/free-solid-svg-icons'
import { useUserStore } from 'store/userStore'
import { useModal } from 'components/Modal'
import Avatar from 'components/Avatar'
import UpdateUserModal from './UpdateUserModal'

function UserCard({ size }: { size: 'sm' | 'lg' | 'md' }) {
  const modal = useModal()
  const { user } = useUserStore()
  const { name, lastname, position, image } = user || { image: '' }
  return (
    <main className={style.userCard}>
      <Avatar avatar={image} fullName={`${name} ${lastname}`} size={size} />
      <div className={style.name}>
        {name} {lastname}
      </div>
      <div className={style.description}>{position}</div>
      <button onClick={() => modal.show()} className={style.expand}>
        <FontAwesomeIcon icon={faMaximize} />
      </button>
      <UpdateUserModal modal={modal} user={user} />
    </main>
  )
}

export default UserCard
