import Style from './loading-screen.module.scss'
import logo from 'assets/logo.svg'

function LoadingPage() {
  return (
    <div className={Style.loading}>
      <img className={Style.logo} src={logo} alt='gogeo-logo' />
    </div>
  )
}

export default LoadingPage
