import styled from 'styled-components'

interface FlexProps {
  gap: number
  justifyContent: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly' | 'stretch'
  flexDirection: 'row' | 'row-reverse' | 'column' | 'column-reverse'
  alignItems: 'stretch' | 'flex-start' | 'flex-end' | 'center' | 'baseline'
}

const Flex = styled.div<Partial<FlexProps>>`
  display: flex;
  gap: ${({ gap }) => gap ?? '20'}px;
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  align-items: ${({ alignItems }) => alignItems || 'center'};
`

export default Flex
