import style from './usermodal.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faChevronRight, faPencil } from '@fortawesome/free-solid-svg-icons'
import Modal, { UseModalHook } from 'components/Modal'
import Button from 'components/Button'
import { UserType } from 'models/user'
import Avatar from 'components/Avatar'
import LabeledInput from 'components/LabeledInput'
import { useState } from 'react'
import styled from 'styled-components'
import Switch from 'components/Switch'

const DemoData = styled.div`
  font-family: 'Ropa Sans';
  font-weight: 400;
  color: #b9b9bc;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
`

const width = [90, 200]

function EditPassword() {
  const [edit, setEdit] = useState<boolean>(false)
  return (
    <LabeledInput
      width={width}
      label='Password'
      action={
        <button onClick={() => setEdit(!edit)}>
          {edit ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faPencil} />}
        </button>
      }
    >
      {edit ? (
        <input autoFocus type='password' onBlur={() => setEdit(false)} />
      ) : (
        <DemoData>*****************</DemoData>
      )}
    </LabeledInput>
  )
}

function EditPhone({ defaultValue }: { defaultValue?: string | null }) {
  const [edit, setEdit] = useState<boolean>(false)
  const [value, setValue] = useState<string>(defaultValue || '')

  return (
    <LabeledInput
      width={width}
      label='Phone'
      action={
        edit ? (
          <button>
            <FontAwesomeIcon icon={faCheck} />
          </button>
        ) : (
          <button onClick={() => setEdit(!edit)}>
            <FontAwesomeIcon icon={faPencil} />
          </button>
        )
      }
    >
      {edit ? (
        <input
          autoFocus
          type='text'
          onChange={event => setValue(event.target.value)}
          defaultValue={value}
          onBlur={() => {
            setEdit(false)
            setValue(defaultValue || '')
          }}
        />
      ) : (
        <DemoData>{defaultValue}</DemoData>
      )}
    </LabeledInput>
  )
}

function UpdateUserModal({ modal, user }: { modal: UseModalHook; user: UserType | null }) {
  const { name, lastname, image, email, phone } = user || { name: '', lastname: '', image: '', email: '' }

  return (
    <Modal modal={modal} title=''>
      <Modal.Body className={style.usermodal}>
        <div className={style.informations}>
          <Avatar avatar={image} fullName={`${name} ${lastname}`} size='lg' />
          <span className={style.name}>{`${name} ${lastname}`}</span>
          <span className={style.email}>{email}</span>
        </div>
        <Button disabled varient='secondary'>
          Edit Profile
        </Button>
        <fieldset>
          <legend>Information</legend>
          <EditPassword />
          <EditPhone defaultValue={phone} />
        </fieldset>

        <fieldset>
          <legend>Prefrences</legend>
          <LabeledInput
            width={width}
            action={
              <label htmlFor='updatelanguage'>
                <FontAwesomeIcon icon={faChevronRight} />
              </label>
            }
            label='Language'
          >
            <select id='updatelanguage' name='language'>
              <option>English</option>
              <option disabled>Français</option>
              <option disabled>العربية</option>
            </select>
          </LabeledInput>
          <LabeledInput width={width} label='Dark mode' action={<Switch disabled />}></LabeledInput>
        </fieldset>
      </Modal.Body>
    </Modal>
  )
}

export default UpdateUserModal
