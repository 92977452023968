import classNames from 'classnames'
import styles from './style.module.scss'

export interface ProgressBarInterface {
  title?: string
  count?: number
  total?: number
  isLoading?: boolean
  hideCount?: boolean
  small?: boolean
}

function ProgressBar({
  title,
  count,
  total = 100,
  isLoading = false,
  hideCount = false,
  small = false,
}: ProgressBarInterface) {
  return (
    <div className={classNames(styles.container, { [styles.loading]: isLoading, [styles.small]: small })}>
      <div className={styles.header}>
        <div className={styles.title}>{!isLoading && title}</div>
        {!hideCount && <div className={styles.count}>{!isLoading && count}</div>}
      </div>
      <div className={styles.progress_container}>
        <div className={styles.progress} style={{ width: `${isLoading ? 100 : ((count || 0) * 100) / total}%` }}></div>
      </div>
    </div>
  )
}

export default ProgressBar
