import { CSSProperties, FC, PropsWithChildren } from 'react'
import Style from './button.module.scss'
import { ColorVarients, SizeVarients, ButtonTypeVarients } from 'types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import styled from 'styled-components'

interface ButtonProps {
  varient?: ColorVarients
  size?: SizeVarients
  block?: boolean
  type?: ButtonTypeVarients
  disabled?: boolean
  isLoading?: boolean
  href?: string
  w100?: boolean
  circle?: boolean
  onClick?: () => void
  extraStyles?: CSSProperties
}

const Button: FC<PropsWithChildren<ButtonProps>> = ({
  children,
  block = false,
  varient = 'primary',
  size = 'md',
  type = 'button',
  disabled = false,
  isLoading = false,
  circle = false,
  href,
  w100 = false,
  onClick = () => null,
  extraStyles,
}) => {
  const btnClassName = classnames(Style.btn, Style[varient], Style[size], {
    [Style.block]: block,
    [Style.circle]: circle,
    [Style.isLoading]: isLoading,
    [Style.w100]: w100,
  })

  if (href)
    return (
      <Link to={href} className={btnClassName} style={extraStyles}>
        {children}
      </Link>
    )
  return (
    <button onClick={onClick} disabled={disabled || isLoading} type={type} className={btnClassName} style={extraStyles}>
      {isLoading ? <FontAwesomeIcon spin icon={faSpinner} /> : children}
    </button>
  )
}

export const IconButton = styled.button`
  background-color: transparent;
  border: none;
`

export default Button
