import { FC, ReactElement, PropsWithChildren } from 'react'
import style from './labeledInput.module.scss'

interface LabeledInputProps {
  label: string
  width?: (number | null)[]
  action?: ReactElement
}

const LabeledInput: FC<PropsWithChildren<LabeledInputProps>> = ({ width = [null, null], label, children, action }) => {
  return (
    <div className={style.container}>
      <label className={style.label} style={{ width: width[0] || 'auto' }}>
        {label}
      </label>
      <div className={style.body} style={{ width: width[1] || 'auto' }}>
        {children}
      </div>
      {action && <div className={style.action}>{action}</div>}
    </div>
  )
}

export default LabeledInput
