import style from './avatar.module.scss'
import defaultAvatar from 'assets/avatars/default.png'
import { FC, PropsWithChildren, useState } from 'react'
import classNames from 'classnames'

interface AvatarProps {
  avatar: string
  fullName: string
  size?: 'x-sm' | 'sm' | 'md' | 'lg'
  title?: string
}

const Avatar: FC<PropsWithChildren<AvatarProps>> = ({ avatar, fullName, size = 'sm', title }) => {
  const [image, setImage] = useState<string>(avatar)
  const [loading, setLoading] = useState<boolean>(true)
  return (
    <div className={classNames(style.avatar, style[size], { [style.loading]: loading })}>
      <img
        onError={() => {
          setImage(defaultAvatar)
          setLoading(false)
        }}
        onLoad={() => setLoading(false)}
        src={image}
        alt={fullName}
        title={title}
      />
    </div>
  )
}

export default Avatar
