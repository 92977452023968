import Style from './loading-screen.module.scss'
import logo from 'assets/logo.svg'
import { Helmet } from 'react-helmet'
import { projectName } from 'settings'

function LoadingScreen() {
  return (
    <>
      <Helmet>
        <title>Loading | {projectName}</title>
      </Helmet>
      <div className={Style.loading}>
        <img className={Style.logo} src={logo} alt='gogeo-logo' />
      </div>
    </>
  )
}

export default LoadingScreen
