import { FC, PropsWithChildren, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUserStore } from 'store/userStore'

const PrivateRoute: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { user, isLoading } = useUserStore()
  const navigate = useNavigate()
  useEffect(() => {
    if (!user && !isLoading) navigate('/auth/login')
  }, [user, isLoading, navigate])

  return <>{children}</>
}

export default PrivateRoute
