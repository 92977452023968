import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'components/Button'
import Card from 'components/Card'
import { Link } from 'react-router-dom'
import ChatIcon from './ChatIcon'
import style from './style.module.scss'
import { chatType } from './types'

/**
 * TODO: To be implemented
 * const { data } = useQuery<chatType[], Error>('get-chat-users', () => axios.get('/chat').then(res => res.data))
 */

function Chat() {
  const data: chatType[] = []

  return (
    <div className={style.chatOuter}>
      <Card>
        <div className={style.chatContainer}>
          <div className={style.chatBullsContainer}>
            {(data || []).map(({ id, ...item }) => (
              <Link key={id} to={`/messages/${id}`}>
                <ChatIcon {...item} id={id} />
              </Link>
            ))}
            <div className={style.spacer}></div>
          </div>
          <div className={style.actions}>
            <Button disabled circle varient='secondary'>
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>
        </div>
      </Card>
    </div>
  )
}

export default Chat
