import { faBug, faCheck, faEnvelope, faExclamation, faFolder } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import Button from 'components/Button'
import Flex from 'components/Flex'
import Input from 'components/Input'
import Modal, { useModal } from 'components/Modal'
import Space from 'components/Space'
import { Field, Form, Formik, FormikHelpers } from 'formik'
import { useRef, useState } from 'react'
import { useMutation } from 'react-query'
import * as Yup from 'yup'
import { Circle, FileInputMask, FormContainer, MessageContainer } from './styled'

interface FormValues {
  email: string
  description: string
  screenshots: FileList | null
  notify: boolean
}

const MAX_UPLOAD_SIZE = 200000

const reportBugSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address').required('Required'),
  description: Yup.string().required('Required'),
  screeshots: Yup.mixed<FileList>().test('fileSize', 'Upload is too large', value => {
    if (!value) return true
    return (
      Object.values(value)
        .map(f => f.size)
        .reduce((a, b) => a + b) <= MAX_UPLOAD_SIZE
    )
  }),
})

const initialValues: FormValues = {
  email: '',
  description: '',
  screenshots: null,
  notify: false,
}

function BugReport({ className, text, ...props }: { className: string; text?: string }) {
  const modal = useModal({ width: 700 })
  const submissionModal = useModal()
  const [success, setSuccess] = useState<boolean>(false)
  const [selectedFiles, setSelectedFiles] = useState<string[]>([])

  const filesRef = useRef<HTMLInputElement>(null)

  const handleSuccess = () => {
    setSuccess(true)
    submissionModal.show()

    setTimeout(() => {
      submissionModal.hide()
    }, 3000)
  }

  const handleFailure = () => {
    setSuccess(false)
    submissionModal.show()

    setTimeout(() => {
      submissionModal.hide()
    }, 3000)
  }

  const { isLoading, mutate: postData } = useMutation(
    'submit-bugreport',
    (data: FormData) => {
      return axios.post('/support/bug/report', data)
    },
    {
      onSuccess: () => {
        handleSuccess()
      },
      onError: () => {
        handleFailure()
      },
    }
  )

  const handleSubmission = (formData: FormValues, actions: FormikHelpers<FormValues>) => {
    const form = new FormData()

    for (const [key, val] of Object.entries(formData)) {
      if (key !== 'screenshots') form.set(key, val)
    }
    Object.entries(formData.screenshots || {}).forEach(e => {
      const [, payload] = e
      form.append('screenshots', payload)
    })

    postData(form)
    actions.resetForm()
  }

  return (
    <Button onClick={modal.toggle} {...props}>
      <Modal modal={submissionModal}>
        <Modal.Body>
          <Space>
            {success ? (
              <MessageContainer>
                <h1>Your Report has been submitted successfully </h1>
                <Circle varient='success'>
                  <FontAwesomeIcon fontSize={40} icon={faCheck} />
                </Circle>
              </MessageContainer>
            ) : (
              <MessageContainer>
                <h1>Failed to submit report</h1>
                <Circle varient='failure'>
                  <FontAwesomeIcon fontSize={40} icon={faExclamation} />
                </Circle>
              </MessageContainer>
            )}
          </Space>
        </Modal.Body>
      </Modal>
      <FontAwesomeIcon icon={faBug} className={className} />
      {text && <span>{text}</span>}
      <Formik validationSchema={reportBugSchema} initialValues={initialValues} onSubmit={handleSubmission}>
        {({ errors, touched, submitForm, setFieldValue, resetForm }) => (
          <Modal modal={modal}>
            <Modal.Body>
              <Flex gap={2} flexDirection='column'>
                <h2>Bug Report Form</h2> <br />
                <p>Use this form to report any bugs or issues you encounter.</p>
              </Flex>
              <Form>
                <FormContainer>
                  <label>
                    Your E-mail <span>*</span>
                  </label>
                  <Input
                    startIcon={<FontAwesomeIcon fontSize={20} icon={faEnvelope} />}
                    name='email'
                    label='email'
                    placeholder='john@doe.xyz'
                    error={Boolean(errors.email) && Boolean(touched.email)}
                  />
                  <div>
                    <label>
                      Steps to reproduce the issue <span>*</span>
                    </label>
                    <p>Please be as detailed as possible! </p>
                  </div>

                  <Input
                    as='textarea'
                    name='description'
                    label='description'
                    placeholder='Description'
                    error={Boolean(errors.description) && Boolean(touched.description)}
                  />
                  <label htmlFor='screenshots'>
                    Screenshots of the issue <span>*</span>
                  </label>
                  <input
                    type='file'
                    ref={filesRef}
                    multiple
                    name='screenshots-back'
                    id='screenshots-back'
                    style={{ display: 'none' }}
                    onChange={c => {
                      const files = c.target.files
                      if (files) setSelectedFiles(Object.values(files).map(f => f.name))

                      setFieldValue('screenshots', files)
                    }}
                  />
                  <FileInputMask
                    onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                      if (filesRef.current) {
                        filesRef.current.click()
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faFolder} fontSize={22} color='#93A0B1' />
                    <p>
                      Drag & Drop a file or <span style={{ color: 'black' }}>browse</span>
                    </p>
                  </FileInputMask>
                  <Flex justifyContent='flex-start'>
                    Selected items:
                    {selectedFiles.length ? (
                      <span style={{ color: 'white' }}>{selectedFiles.join(', ')}</span>
                    ) : (
                      <span>None selected yet</span>
                    )}
                  </Flex>

                  <Flex gap={4} justifyContent='flex-start'>
                    <Field type='checkbox' id='notify' name='notify'></Field>
                    Do you want to be notified when the issue is resolved ?
                  </Flex>
                </FormContainer>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Flex justifyContent='stretch' style={{ width: '100%' }}>
                <Button
                  type='submit'
                  varient='secondary'
                  onClick={() => {
                    submitForm()
                  }}
                  extraStyles={{ width: 'inherit' }}
                  isLoading={isLoading}
                >
                  Report
                </Button>
                <Button
                  varient='danger'
                  extraStyles={{ width: 'inherit' }}
                  isLoading={isLoading}
                  onClick={() => {
                    resetForm()
                    setSelectedFiles([])
                    modal.hide()
                  }}
                >
                  Discard
                </Button>
              </Flex>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </Button>
  )
}

export default BugReport
