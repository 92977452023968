import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { getWindowDimensions } from 'helpers/window'
import { DragEvent, FC, MutableRefObject, ReactElement, useCallback, useEffect, useRef, useState, PropsWithChildren } from 'react'
import { Portal } from 'react-portal'
import { LoaderFunction } from 'types'
import style from './modal.module.scss'

interface ModalPosition {
  top: number
  left: number
}
export interface UseModalHook {
  modalRef: MutableRefObject<HTMLDivElement | null>
  visible: boolean
  position: ModalPosition
  width: string | number
  height: string | number
  show: () => void
  hide: () => void
  toggle: () => void
  resetPosition: () => void
  dragEvent: {
    onDragStart: LoaderFunction<DragEvent<HTMLDivElement>>
    onDrag: LoaderFunction<DragEvent<HTMLDivElement>>
    onDragEnd: LoaderFunction<DragEvent<HTMLDivElement>>
  }
}

interface ModalProps {
  modal: UseModalHook
  title?: ReactElement | string
}

interface useModalProps {
  show?: boolean
  width?: null | string | number
  height?: null | string | number
  beforeHide?: () => void
  beforeShow?: () => void
}

// TODO: Create TimedMessage component with varients (success, failure)

export const useModal = (props?: useModalProps): UseModalHook => {
  const { show: isvisible = false, width = null, height = null } = props || {}
  const modalRef = useRef<HTMLDivElement>(null)
  const [visible, setVisible] = useState<boolean>(Boolean(isvisible))
  const [position, setPosition] = useState<ModalPosition>({ top: 0, left: 0 })
  const [oldPosition, setOldPosition] = useState<ModalPosition>({ top: 0, left: 0 })
  const [startPosition, setStartPosition] = useState<ModalPosition>({ top: 0, left: 0 })

  function handleResize() {
    const { width, height } = getWindowDimensions()
    let [top, left] = [0, 0]
    const { current } = modalRef
    if (!current) {
      top = height / 2
      left = width / 2
    } else {
      const { width: modalWidth, height: modalHeight } = current.getBoundingClientRect()
      top = height / 2 - modalHeight / 2
      left = width / 2 - modalWidth / 2
    }

    setPosition({ top, left })
    setOldPosition({ top, left })
  }

  const hide = useCallback(() => {
    if (props && props.beforeHide) props.beforeHide()
    setVisible(false)
  }, [props])

  const show = useCallback(() => {
    if (props && props.beforeShow) props.beforeShow()
    setVisible(true)
  }, [props])

  useEffect(() => {
    if (visible) handleResize()
  }, [visible])

  useEffect(() => {
    document.addEventListener('keydown', (event: KeyboardEvent) => {
      if (event.code === 'Escape') hide()
    })
    return () =>
      document.removeEventListener('keydown', (event: KeyboardEvent) => {
        if (event.code === 'Escape') hide()
      })
  }, [hide])

  const toggle = () => setVisible(!visible)

  const dragStart = ({ pageY, pageX, dataTransfer }: DragEvent<HTMLDivElement>) => {
    var img = document.createElement('img')
    dataTransfer.setDragImage(img, 0, 0)
    setStartPosition({ top: pageY, left: pageX })
  }

  // BUG: Drag and drop not working on firefox
  const drag = ({ pageX, pageY }: DragEvent<HTMLDivElement>) => {
    const { current } = modalRef
    const { width, height } = getWindowDimensions()

    if (!current) return

    const { top: startTop, left: startLeft } = startPosition
    const { top: oldTop, left: oldLeft } = oldPosition
    const { width: modalWidth, height: modalHeight } = current.getBoundingClientRect()
    let currentTop = oldTop + pageY - startTop
    let currentLeft = oldLeft + pageX - startLeft
    if (currentTop < 0) currentTop = 0
    if (currentLeft < 0) currentLeft = 0
    if (currentTop + modalHeight > height) currentTop = height - modalHeight
    if (currentLeft + modalWidth > width) currentLeft = width - modalWidth
    current.style.top = `${currentTop}px`
    current.style.left = `${currentLeft}px`
  }

  const dragEnd = ({ pageX, pageY }: DragEvent<HTMLDivElement>) => {
    const { current } = modalRef
    if (!current) return
    const { width, height } = getWindowDimensions()
    const { width: modalWidth, height: modalHeight } = current.getBoundingClientRect()
    const { top: startTop, left: startLeft } = startPosition
    const { top: oldTop, left: oldLeft } = oldPosition

    let currentTop = oldTop + pageY - startTop
    let currentLeft = oldLeft + pageX - startLeft
    if (currentTop < 0) currentTop = 0
    if (currentLeft < 0) currentLeft = 0
    if (currentTop + modalHeight > height) currentTop = height - modalHeight
    if (currentLeft + modalWidth > width) currentLeft = width - modalWidth

    setPosition({ top: currentTop, left: currentLeft })
    setStartPosition({ top: 0, left: 0 })
    setOldPosition({ top: currentTop, left: currentLeft })
  }

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return {
    modalRef,
    visible,
    show,
    hide,
    toggle,
    position,
    width: width || '',
    height: height || '',
    resetPosition: handleResize,
    dragEvent: { onDragStart: dragStart, onDrag: drag, onDragEnd: dragEnd },
  }
}

const Modal: FC<PropsWithChildren<ModalProps>> = ({ modal, title = '', children }) => {
  const { modalRef, dragEvent, visible, position, hide, width, height } = modal

  return visible ? (
    <Portal>
      <div role='dialog' className={style.modalDrop} onClick={hide} tabIndex={-1}>
        <div
          ref={modalRef}
          style={{ ...position, width, height }}
          className={style.modal}
          onClick={event => event.stopPropagation()}
        >
          <div draggable {...dragEvent} className={style.modalHeader}>
            <span>{title}</span>
            <button onClick={hide}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          {children}
        </div>
      </div>
    </Portal>
  ) : null
}

const ModalBody: FC<PropsWithChildren<{ noPadding?: boolean; className?: string; hasFooter?: boolean }>> = ({
  children,
  noPadding = false,
  className = '',
  hasFooter = false,
}) => (
  <div
    className={classNames(style.modalBody, className, { [style.noPadding]: noPadding, [style.hasFooter]: hasFooter })}
  >
    {children}
  </div>
)

const ModalFooter: FC<PropsWithChildren<{ center?: boolean }>> = ({ children, center = false }) => (
  <div className={classNames(style.modalFooter, { [style.center]: center })}>{children}</div>
)

const ModalTitle: FC<PropsWithChildren<{ icon?: string }>> = ({ children, icon = '?' }) => (
  <div className={style.title}>
    {children}
    <span className={style.icon}>{icon}</span>
  </div>
)

const ModalMessage: FC<
  PropsWithChildren<{ varient?: 'primary' | 'secondary' | 'danger' | 'success' | 'info' | 'warning' | 'light' }>
> = ({ children, varient = 'primary' }) => <div className={classNames(style.message, style[varient])}> {children}</div>

export default Object.assign(Modal, {
  useModal,
  Body: ModalBody,
  Footer: ModalFooter,
  Title: ModalTitle,
  Message: ModalMessage,
})
