/* eslint-disable react/display-name */
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import logo from 'assets/logo.svg'
import classNames from 'classnames'
import { CSSProperties, FC, useEffect, PropsWithChildren } from 'react'
import { Helmet } from 'react-helmet'
import { Link, Outlet, useNavigate } from 'react-router-dom'
import { projectName } from 'settings'
import { useUserStore } from 'store/userStore'
import css from './authlayout.module.scss'

const AuthLayout = () => {
  const { user } = useUserStore()
  const navigate = useNavigate()

  useEffect(() => {
    if (user) navigate('/')
  }, [navigate, user])

  return (
    <div className={css.authContainer}>
      <Outlet />
    </div>
  )
}

export const Card: FC<PropsWithChildren<{ dark?: boolean; title: string; style?: CSSProperties }>> = ({
  children,
  title,
  dark = false,
  style = {},
}) => {
  return (
    <>
      <Helmet>
        <title>
          {title} | {projectName}
        </title>
      </Helmet>
      <div style={style} className={classNames({ [css.dark]: dark, [css.authCard]: true })}>
        <img className={css.logo} src={logo} alt='geoscan' />
        {children}
      </div>
    </>
  )
}

const NotFound: FC<PropsWithChildren<unknown>> = () => {
  return (
    <div className={css.authCard}>
      <img className={css.logo} src={logo} alt='geoscan' />
      <h1 className={css.notfoundTitle}>This page doesn&apos;t exists</h1>
      <Link className={css.notfoundLink} to='/'>
        <FontAwesomeIcon icon={faArrowLeft} />
        Back
      </Link>
    </div>
  )
}

AuthLayout.Card = Card
AuthLayout.NotFound = NotFound

export default AuthLayout
