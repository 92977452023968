import { lazy, Suspense, useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { useUserStore } from 'store/userStore'
import { myProjectStore } from 'store/myProjectStore'
import LoadingScreen from 'views/LoadingScreen'
import AuthLayout from 'layouts/AuthLayout'
import Dashboard from 'views/Dashboard'
import PageNotFound from 'views/Dashboard/PageNotFound'

const LoginView = lazy(() => import('views/Login'))
const ResetRequest = lazy(() => import('views/ResetRequest'))
const MainDashboard = lazy(() => import('views/Dashboard/MainDashboard'))
const ProjectRouter = lazy(() => import('views/Dashboard/Projects'))
const MemberRouter = lazy(() => import('views/Dashboard/Members'))
const ResetPassword = lazy(() => import('views/ResetPassword'))
const Plugins = lazy(() => import('views/Dashboard/Plugins'))

function App() {
  const { user, isLoading, verifyUser } = useUserStore()
  const { getProjects } = myProjectStore()

  useEffect(() => {
    verifyUser()
  }, [verifyUser])

  useEffect(() => {
    if (user) getProjects()
  }, [getProjects, user])

  if (isLoading) return <LoadingScreen />

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Routes>
        <Route path='/' element={<Dashboard />}>
          <Route index element={<MainDashboard />} />
          <Route path='projects/*' element={<ProjectRouter />} />
          <Route path='members/*' element={<MemberRouter />} />
          <Route path='plugins/*' element={<Plugins />} />
          <Route path='*' element={<PageNotFound />} />
        </Route>
        <Route path='/auth' element={<AuthLayout />}>
          <Route path='login' element={<LoginView />} />
          <Route path='forgot-password'>
            <Route index element={<ResetRequest />} />
            <Route path=':user/:token' element={<ResetPassword />} />
          </Route>
          <Route path='*' element={<AuthLayout.NotFound />} />
        </Route>
      </Routes>
    </Suspense>
  )
}

export default App
