import classNames from 'classnames'
import styled from 'styled-components'

const SwitchContainer = styled.label<{ disabled?: boolean }>`
  position: relative;
  display: inline-block;
  width: 2em;
  height: 1em;
  top: 3px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.38);
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;
    &::before {
      position: absolute;
      content: '';
      height: calc(1em + 6px);
      width: calc(1em + 6px);
      left: -3px;
      top: -3px;
      background-color: rgba(102, 102, 102, 0.6);
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 50%;
    }
  }

  input:checked + .slider {
    background-color: rgba(0, 173, 181, 0.5);
  }

  input:focus + .slider {
    box-shadow: 0 0 1px rgba(0, 173, 181, 0.5);
  }

  input:checked + .slider:before {
    background: #00adb5;
    -webkit-transform: translateX(1em);
    -ms-transform: translateX(1em);
    transform: translateX(1em);
  }

  &.loading {
    .slider {
      cursor: wait;
      &:before {
        background: #00adb5;
        -webkit-transform: translateX(0.5em);
        -ms-transform: translateX(0.5em);
        transform: translateX(0.5em);
      }
    }
    input:checked + .slider:before {
      background: #00adb5;
      -webkit-transform: translateX(0.5em);
      -ms-transform: translateX(0.5em);
      transform: translateX(0.5em);
    }
  }
`

interface SwitchProps {
  disabled?: boolean
  checked?: boolean
  onChange?: () => void
  isLoading?: boolean
}

function Switch({ checked, disabled = false, onChange, isLoading = false }: SwitchProps) {
  return (
    <SwitchContainer className={classNames({ loading: isLoading })} disabled={disabled}>
      <input checked={checked} onChange={onChange} disabled={disabled} type='checkbox' />
      <span className='slider'></span>
    </SwitchContainer>
  )
}

export default Switch
