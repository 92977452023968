import styles from './card.module.scss'
import {
  Children,
  cloneElement,
  CSSProperties,
  DragEvent,
  FC,
  forwardRef,
  isValidElement,
  PropsWithChildren,
  ReactNode,
  useRef,
  useState,
} from 'react'
import classNames from 'classnames'
import { CardBody, CardFooter, CardHeader, CardTitle, ErrorCardDiv, LoadingCard, LoadingDiv } from './Components'
import { CardTabsHeader, CardTabsItem, CardTabsProps, TabsBody } from './Tabs'
import { ColorVarients, OneNonRequiredParam } from 'types'

interface CardProps {
  border?: Omit<ColorVarients, 'text'> | false
  noScroll?: boolean
  maxHeight?: number
  noShadow?: boolean
  children?: ReactNode
  grow?: number
  draggable?: boolean
  onDragEnd?: OneNonRequiredParam<DragEvent<HTMLDivElement>>
  onDragStart?: OneNonRequiredParam<DragEvent<HTMLDivElement>>
  style?: CSSProperties
}

const Card = forwardRef<HTMLDivElement | null, CardProps>((props, ref) => {
  const {
    children,
    noScroll = false,
    maxHeight,
    border = false,
    noShadow = false,
    grow = 0,
    draggable = false,
    onDragStart,
    onDragEnd,
    style = {},
  } = props

  const [position, setPosition] = useState<DOMRect | null>(null)
  const customStyle: CSSProperties = style
  if (maxHeight) customStyle.maxHeight = `${maxHeight}px`
  const [isDragging, setIsDragging] = useState<boolean>(false)
  const cardRef = useRef<HTMLDivElement | null>(null)

  return (
    <>
      <div
        ref={ref ? ref : cardRef}
        className={classNames(
          styles.card,
          { [styles.noScroll]: noScroll, [styles.noShadow]: noShadow, [styles.dragging]: isDragging },
          border && { [styles[`border-${border}` || '']]: border }
        )}
        style={{ ...customStyle, flexGrow: grow }}
      >
        {Children.map(children, child => {
          if (!isValidElement(child)) return null
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (child.type.name === CardHeader.name)
            return cloneElement(child, {
              draggable,
              onDragStart: (event: DragEvent<HTMLDivElement>) => {
                setIsDragging(true)
                if (onDragStart) onDragStart(event)
              },
              onDragEnd: () => {
                setIsDragging(false)
                setPosition(null)
                if (onDragEnd) onDragEnd()
              },
            })
          return child
        })}
      </div>
      {isDragging && (
        <div
          style={{
            width: position?.width,
            height: position?.height,
          }}
          className={styles.placeHolder}
        ></div>
      )}
    </>
  )
})

Card.displayName = 'Card'

export const ErrorCard: FC<PropsWithChildren<{ style?: CSSProperties }>> = ({ children, style = {} }) => {
  return (
    <Card style={style}>
      <ErrorCardDiv>{children}</ErrorCardDiv>
    </Card>
  )
}

function Tabs({ children, defaultSelected }: CardTabsProps) {
  const [selected, setSelected] = useState<string>(defaultSelected)

  return (
    <Card>
      <CardTabsHeader selected={selected} setSelected={setSelected}>
        {children}
      </CardTabsHeader>
      <TabsBody selected={selected}>{children}</TabsBody>
    </Card>
  )
}

export default Object.assign(Card, {
  Header: CardHeader,
  Title: CardTitle,
  Body: CardBody,
  Footer: CardFooter,
  Loading: LoadingCard,
  Error: ErrorCard,
  ErrorDiv: ErrorCardDiv,
  LoadingDiv: LoadingDiv,
  Tabs,
  TabsItem: CardTabsItem,
})
