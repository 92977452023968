import style from './card.module.scss'
import { CSSProperties, FC, forwardRef, PropsWithChildren, ReactNode, SVGProps } from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsis } from '@fortawesome/free-solid-svg-icons'
import scssVariables from './card.variables.scss'
import styled from 'styled-components'

export const defaultIcon = <FontAwesomeIcon icon={faEllipsis} />

export const ErrorCardDiv = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: ${scssVariables.cardPadding};
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 18px;
  font-weight: 700;
  user-select: none;
  padding: calc(${scssVariables.cardPadding} * 2);
  background: linear-gradient(-45deg, #ff416c, #ff4b2b);
  a,
  button {
    cursor: pointer;
    padding: 0.5em 3em;
    border-radius: 5px;
    border: 0px;
    background: white;
    &:hover {
      background: #eee;
    }
    color: ${scssVariables.linkColor};
  }
`

export const ActionButton = styled.button`
  border: 0px;
  color: ${scssVariables.actionColor};
  background: 0px;
  cursor: pointer;

  &:hover {
    color: ${scssVariables.actionColorHover};
  }
`

export const CardTitleContainer = styled.div`
  width: fit-content;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  line-height: 1em;
  padding: ${scssVariables.cardPadding} 0px;
`

export const Title = styled.span`
  color: #30475e;
`

export const Details = styled.span`
  margin-inline-start: 8px;
  color: grey;
`

export const CardFooter = styled.footer`
  padding: calc(${scssVariables.cardPadding} / 2) ${scssVariables.cardPadding};
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

interface CardHeaderProps {
  draggable?: boolean
  onDragEnd?: () => void
  onDragStart?: () => void
  onDrag?: () => void
  icon?: ReactNode
  noIcon?: boolean
  onClick?: () => void
  children: ReactNode[] | ReactNode
}

export const CardHeader = forwardRef<HTMLDivElement, Partial<CardHeaderProps>>(
  (
    {
      children,
      noIcon = false,
      icon = defaultIcon,
      onClick = () => null,
      draggable = false,
      onDragEnd,
      onDrag,
      onDragStart,
    },
    ref
  ) => {
    return (
      <header
        ref={ref}
        draggable={draggable}
        onDragEnd={onDragEnd}
        onDrag={onDrag}
        onDragStart={onDragStart}
        className={classNames({ [style.cardHeader]: true, [style.noChildren]: !children })}
      >
        {children}
        {noIcon ? null : <ActionButton onClick={onClick}>{icon}</ActionButton>}
      </header>
    )
  }
)

CardHeader.displayName = 'CardHeader'

export const CardTitle: FC<PropsWithChildren<{ details?: ReactNode }>> = ({ children, details }) => {
  return (
    <CardTitleContainer>
      <Title>{children}</Title>
      {details && <Details>{details}</Details>}
    </CardTitleContainer>
  )
}

interface CardBodyProps {
  noPadding: boolean
  scroll: boolean
  background: string
  children: ReactNode[] | ReactNode
  h100: boolean
  styles?: CSSProperties
  center?: boolean
}

export const CardBody = forwardRef<HTMLDivElement, Partial<CardBodyProps>>(
  ({ noPadding = false, children, scroll = false, background, h100 = false, styles, center }, ref) => {
    return (
      <main
        ref={ref}
        style={{ background, ...styles }}
        className={classNames(style.cardBody, {
          [style.noPadding]: noPadding,
          [style.scroll]: scroll,
          [style['h-100']]: h100,
          [style.center]: center,
        })}
      >
        {children}
      </main>
    )
  }
)

CardBody.displayName = 'CardBody'

export const LoadingCard = () => {
  return (
    <div className={style.card}>
      <div className={style.loading}></div>
    </div>
  )
}

const pathProps: SVGProps<SVGPathElement> = {
  strokeWidth: 8,
  strokeLinecap: 'round',
  strokeLinejoin: 'round',
}

export const LoadingDiv = () => {
  return (
    <div className={style.loading}>
      <svg
        width='90'
        height='90'
        viewBox='55 22 67.824623 53.281054'
        xmlns='http://www.w3.org/2000/svg'
        className={style.loadingSvg}
      >
        <path {...pathProps} d='m 82.019503,22.907658 c 4.647185,-1.245211 9.337021,-1.172429 13.672641,-0.01079' />
        <path {...pathProps} d='m 82.019503,22.907658 c 4.647185,-1.245211 9.337021,-1.172429 13.672641,-0.01079' />
        <path {...pathProps} d='m 82.019503,22.907658 c 4.647185,-1.245211 9.337021,-1.172429 13.672641,-0.01079' />
        <path {...pathProps} d='m 82.019503,22.907658 c 4.647185,-1.245211 9.337021,-1.172429 13.672641,-0.01079' />
        <path {...pathProps} d='m 82.019503,22.907658 c 4.647185,-1.245211 9.337021,-1.172429 13.672641,-0.01079' />
        <path {...pathProps} d='m 82.019503,22.907658 c 4.647185,-1.245211 9.337021,-1.172429 13.672641,-0.01079' />
        <path {...pathProps} d='m 82.019503,22.907658 c 4.647185,-1.245211 9.337021,-1.172429 13.672641,-0.01079' />
        <path {...pathProps} d='m 82.019503,22.907658 c 4.647185,-1.245211 9.337021,-1.172429 13.672641,-0.01079' />
        <path {...pathProps} d='m 82.019503,22.907658 c 4.647185,-1.245211 9.337021,-1.172429 13.672641,-0.01079' />
      </svg>
    </div>
  )
}
