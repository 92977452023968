import style from './sidebar.module.scss'
import UserCard from './UserCard'
import Navigation from './Navigation'
import logoText from 'assets/logo-text-white.svg'
import logo from 'assets/logo-white.svg'
import classNames from 'classnames'
import Logout from './Logout'
import { useContext } from 'react'
import Dashboard from 'views/Dashboard'

function DragBar() {
  const { closed, toggle } = useContext(Dashboard.Context)
  return (
    <div
      onDrag={event => {
        if ((closed && event.clientX > 250) || (!closed && event.clientX < 120)) toggle()
      }}
      draggable
      className={style.resize}
      onDragEnd={event => {
        if ((closed && event.clientX > 250) || (!closed && event.clientX < 120)) toggle()
      }}
    ></div>
  )
}

function Sidebar() {
  const { closed } = useContext(Dashboard.Context)
  return (
    <aside className={classNames(style.sidebar, { [style.closed]: closed })}>
      <header>
        <img src={closed ? logo : logoText} alt='geoscan' className={style.logo} />
      </header>
      <Navigation />
      <UserCard size={closed ? 'md' : 'lg'} />
      <footer style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Logout />
      </footer>
      <DragBar />
    </aside>
  )
}

export default Sidebar
